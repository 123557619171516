import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

import MenuBar from './../components/Menubar';
import ItemNew from './../components/ItemNew';
import LoaderContext from './../components/LoaderContext';
import LoginPage from './LoginPage';

import MapImage from '../images/map-icon.jpg';

type Props = {
  user: any;
  restaurantPage : Boolean;
  handleLogin(user): void;
};


export const generateDollarSigns = (count: number): string => {
  return '$'.repeat(count);
};

const handleMainFilter = (value: boolean) => {
}

const RestaurantPage: React.FC<Props> = (props) => {
  const [error, setError] = useState('');
  const [items, setItems] = useState<any[]>([]);
  const [restaurant, setRestaurant] = useState<any>({});
  const [showLogin, setShowLogin] = useState(false);
  const [isExpired, setIsexpired] = useState(false);
  const { id }: any = useParams();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [clickCount, setClickCount] = useState(0);
  let didCancel = false;

  const log = async (message: any) => {
    if (process.env.REACT_APP_LOG === 'true') {
      console.log(message);
    }
  };

  const loadData = async () => {
    showLoader();

    var headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    if (props.user.isLogged) {
      headers['Authorization'] = 'Bearer ' + props.user.token;
    }

    axios
      .get(process.env.REACT_APP_SERVER_URL + '/restaurants/' + id + '?v=' + Date.now(), {
        headers: headers,
      })
      .then((result) => {
        console.log(result);
        if (!didCancel) {
          hideLoader();
          setItems(result.data.items);
          setRestaurant(result.data.restaurant);
          setError('');
        }
      })
      .catch((err) => {
        log(err);
        if (!didCancel) {
          hideLoader();
          setError('Network error!');
        }
      });
  };

  useEffect(() => {
    loadData();
    return () => {
      didCancel = true;
    };
  }, [props.user.isLogged]);

  const handleUpvote = async (id) => {
    showLoader();
    const data = { userId: props.user.id };
    fetch(process.env.REACT_APP_SERVER_URL + '/items/' + id + '/upvote', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + props.user.token,
      }),
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          log(result);
          setItems(
            items.map((item) => (item.id === id ? { ...item, upvoted: true, upvoteCount: item.upvoteCount + 1 } : item))
          );
          setError('');
          hideLoader();
        },
        (error) => {
          log(error);
          setError(error.message);
          hideLoader();
        }
      );
  };

  const onLoginClick = async () => {
    setShowLogin(true);
  };

  const onLoginBackClick = async () => {
    setShowLogin(false);
  };

  const handleRemoveUpvote = async (id) => {

  };

  const handleRestaurantClick = async (id) => {
    sendRestaurantNotification(id);
  }

  const sendRestaurantNotification = (id) => {
    try {
      const stored = localStorage.getItem('YoloMeal');
      console.log(stored);
      if (stored) {
        showLoader();
        const userData = JSON.parse(stored);
        fetch(
          process.env.REACT_APP_SERVER_URL +
          `/restaurant/markClosed?restaurantId=${id}&userId=${userData.id}`,
          {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + userData.token,
            }),
          },
        )
          .then(
            (result) => {
              setRestaurant( (restaurant: any) => ({ ...restaurant, restaurantClosed: true}));
              console.log(restaurant);
              hideLoader();
            },
            (error) => {
              hideLoader();
              console.log(error);
            },
          );

      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleNotificationClick= async(id)=>{
      sendNotification(id); 
  }

  const sendNotification = (id) => {
    try {
			const stored = localStorage.getItem('YoloMeal');
      console.log(stored);
			if (stored) {
				showLoader();
				const userData = JSON.parse(stored);
				fetch(
					process.env.REACT_APP_SERVER_URL +
					`/itemExpired?itemId=${id}&userId=${userData.id}` ,
					{
						method: 'GET',
						headers: new Headers({
							'Content-Type': 'application/json',
							Accept: 'application/json',
							Authorization: 'Bearer ' + userData.token,
						})
					}
				)
					.then(
						(result) => {
              
              setItems(
                items.map((item) => (item.id === id ? { ...item, expired: true} : item))
              )

         
          // console.log(updatedItems);
              console.log(items);
              
							hideLoader();
						},
						(error) => {
							hideLoader();
							console.log(error)
						}
					);

			}
		} catch (error) {
			console.error('Error fetching categories:', error);
		}
  };

  //const url = "https://www.google.com/maps/place/?q=place_id:" + restaurant.placeId;
  const url =
    'https://www.google.com/maps/search/?api=1&query=' +
    encodeURI(restaurant.name) +
    '&query_place_id=' +
    restaurant.placeId;

  if (showLogin && !props.user.isLogged) {
    return (
      <LoginPage
        user={props.user}
        handleLogin={props.handleLogin}
        showBack={true}
        showHome={false}
        backgroundImage={true}
        handleBack={onLoginBackClick}
      />
    );
  } else {
    return (
      <main className="main-bg address-bg restaurant-page">
        <div className="small-container ">
          <MenuBar user={props.user} />
          <div className="address-info">
            {items.length > 0 && (
              <div className="map-box pb-4">
                <div className="restaurant-box bg-white">
                  <a href={url} target="_blank" rel="noreferrer" className="map-link">
                    <p>{restaurant.name}  {generateDollarSigns(restaurant.priceLevel)}</p>
                    <p>{restaurant.address}</p>
                    <img src={MapImage} alt="map-icon" />
                  </a>
                  <div className="text-center">
                    {props.user.isLogged && props.restaurantPage && !restaurant.restaurantClosed && (
                      <a className="tap-to-closed-btn" style={{ cursor: 'pointer' }}
                         onClick={() => handleRestaurantClick(restaurant.id)}>
                        Restaurant Permanently Closed?
                      </a>
                    )}
                    {props.user.isLogged && props.restaurantPage && restaurant.restaurantClosed && (
                      <a className="tap-to-thanks-btn disabled" style={{ cursor: 'pointer' }}>Thanks for reporting</a>
                    )}
                  </div>
                </div>
              </div>
            )}

            {items.map((item) => (

              <ItemNew
                handleLogin={onLoginClick}
                item={item}
                editable={false}
                isLogged={props.user.isLogged}
                user={props.user}
                showRestaurant={false}
                handleUpvote={handleUpvote}
                handleRemoveUpvote={handleRemoveUpvote}
                handleNotificationClick={handleNotificationClick}
                restaurentPage={true}
                loadData={loadData}
                handleMainFilter={handleMainFilter}
              />
            ))}

            {error !== '' && <p>{error}</p>}
          </div>
          <div className="mt-4 d-flex white justify-content-space-between align-items-center pb-2 uppercase">
            <h1>
              <Link to="/home">Home</Link>
            </h1>
          </div>
        </div>
      </main>
    );
  }
};

export default RestaurantPage;
