import React, { useEffect, useState } from 'react';

type Props = {
  location: any;
  handlePlaceChanged(place): void;
  handlePlaceError(error): void;
};

const FindRestaurant: React.FC<Props> = (props) => {
  const [isLocationAvailable, setIsLocationAvailable] = useState(false);
  const [location, setLocation] = useState({ name: '', lat: 0, lng: 0 });
  const [restaurantName, setRestaurantName] = useState("");
  const [hasError, setHasError] = useState(false);

  const newLocation = async (place, name) => {
    var obj = {
      name: name,
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };
    setLocation(obj);
    setIsLocationAvailable(true);
  };

  const handlePlaceChange = (place) =>{
    if (place.geometry && place.address_components) {

      const addressComponents = place.address_components;
      let fullName : String = '';

      const country = addressComponents.filter(component =>
        component['types'].includes('country')
      ).map(component => {
        return component;
      });

      const state = addressComponents.filter(component =>
        component['types'].includes('administrative_area_level_1')
      ).map(component => {
        return component;
      });

      const locality = addressComponents.filter(component =>
        component['types'].includes('locality')
      ).map(component => {
        return component;
      });

      if (locality.length > 0){
        fullName = `${locality[0].long_name}, ${state[0].short_name}, ${country[0].short_name}`
        newLocation(place, fullName);
        return;
      }

      const administrativeArea = addressComponents.filter(component =>
        component['types'].includes('administrative_area_level_2')
      ).map(component => {
        return component;
      });

      if (administrativeArea.length > 0) {
        fullName = `${administrativeArea[0].long_name}, ${state[0].short_name}, ${country[0].short_name}`
        newLocation(place, fullName);
        return;
      }
    }
    props.location = ({ lat: 0, lng: 0, name: '' });
  }

  useEffect(() => {
    if (props.location.lat !== 0 && props.location.lng !== 0) {
      setLocation(props.location)
      setIsLocationAvailable(true);
    } else {
      setIsLocationAvailable(false);
    }
  }, [props.location]);

  useEffect(() => {
    const center = { lat: props.location.lat, lng: props.location.lng };

    const cityInput = document.getElementById('city-name') as HTMLInputElement;

    const defaultBounds = {
      north: center.lat + 0.1,
      south: center.lat - 0.1,
      east: center.lng + 0.1,
      west: center.lng - 0.1,
    };

    const options = {
      bounds:  defaultBounds,
      fields: ['address_components', 'geometry'],
      origin: center,
      strictBounds:  false,
      types: ['geocode'],
    };

    if(cityInput){
      const cityAutocomplete = new google.maps.places.Autocomplete(cityInput, options);
      cityAutocomplete.addListener('place_changed',function () {
        var place = cityAutocomplete.getPlace();
        handlePlaceChange(place);
      })
    }
  }, [props.location, isLocationAvailable]);

  useEffect(() => {
    if (!isLocationAvailable) return;
    const center = { lat: location.lat, lng: location.lng };
    const circle = new google.maps.Circle({ center, radius: 50 * 1609.344 });

    const options = {
      bounds: circle.getBounds(),
      fields: ['place_id', 'address_components', 'geometry', 'name'],
      origin: center,
      strictBounds: true,
      types: ['establishment'],
    };
    const input = document.getElementById('restaurant-name') as HTMLInputElement;

    const autocomplete = new google.maps.places.Autocomplete(input, options);
    console.log("autocomplete", autocomplete)


    autocomplete.addListener('place_changed', function () {
      var place = autocomplete.getPlace();
      //console.log(place);

      if (place.geometry && place.address_components && place.name !== '' && place.place_id !== '') {
        var address = [
          (place.address_components[0] && place.address_components[0].short_name) || '',
          (place.address_components[1] && place.address_components[1].short_name) || '',
          (place.address_components[2] && place.address_components[2].short_name) || '',
        ].join(' ');
        //console.log(address);

        var obj = {
          id: place.place_id,
          name: place.name,
          address: address,
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
          response: JSON.stringify(place),
        };
        props.handlePlaceChanged(obj);
        setRestaurantName("");
      } else {
        props.handlePlaceError('Error!');
        setRestaurantName("");
      }
    });

  }, [location, isLocationAvailable]);

  const handleInputValue = (e) => {
    setRestaurantName(e.target.value);
    setHasError(false);
  };

  return (
    <div className="yolo-container text-center">
      <h2 className="futuralt-book mt-4 pt-2 mb-2">{props.location.name}</h2>
      <input
        id="city-name"
        className="field xl-filed"
        disabled={isLocationAvailable}
        placeholder={props.location.name ? props.location.name : "Enter your desired city."}
      />
      <input
        autoFocus
        id="restaurant-name"
        className="field xl-filed text-center mt-4 mb-4"
        disabled={!isLocationAvailable}
        placeholder={isLocationAvailable ? 'ENTER RESTAURANT NAME HERE' : 'Select City Above'}
        value={hasError ? "" : restaurantName}
        onChange={handleInputValue}
      />
    </div>
  );
};

export default FindRestaurant;
