import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import TextTruncate from 'react-text-truncate';

import LocationPointer from '../images/location-pointer.svg';
import MissingPhoto from '../images/missing-photo.png';
import DeleteModal from '../modals/DeleteModal';
import EditYoloDescriptionModal from '../modals/EditYoloDescriptionModal';
import UploadPhotoModal from '../modals/UploadPhotoModal';
import ChangeCategoryModal from '../modals/ChangeCategoryModal';
import AddMoreTagsModal from '../modals/AddMoreTagsModal';
import ConfirmationModal from '../modals/ConfirmationModal';
import TagsConfirmationModal from '../modals/TagsConfirmationModal';
import LoaderContext from './LoaderContext';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { tagArray } from '../utils/helpers';
import { generateDollarSigns } from '../pages/RestaurantPage';
import EditYoloNameModal from '../modals/EditYoloNameModal';

type Props = {
  item: any;
  editable: Boolean;
  isLogged: Boolean;
  showRestaurant: Boolean;
  restaurentPage: Boolean;
  user: any
  handleUpvote(id): void;
  handleLogin(): void;
  handleRemoveUpvote(id): void;
  handleNotificationClick(id): void;
  loadData(): void;
  handleMainFilter:(value: boolean) => void
};

const ItemNew: React.FC<Props> = (props) => {
  const [selectedItemCategory, setSelectedItemCategory] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [readMore, setReadMore] = useState(true);
  const [YoloSetting, setYoloSetting] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showEditYoloName, setShowEditYoloName] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [yoloId, setYoloId] = useState(-1);
  const [leftCounter, setLeftCounter] = useState(300);
  const [description, setDescription] = useState('');
  const [yoloName, setYoloName] = useState('');
  const [userName, setUserName] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showtagsModal, setShowtagsModal] = useState(false);
  const [tags, setTags] = useState<any[]>([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [showTags, setShowTags] = useState(false);
  const [categories, setCategories] = useState<any[]>([]);
  const [oldImage, setOldImage] =useState(null);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ['.jpeg', '.jpg', '.png']
  });
  const [error, setError] = useState('');
  const { showLoader, hideLoader } = useContext(LoaderContext);

  const files = acceptedFiles.map(file => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  let didCancel = false;

  useEffect(() => {
    if (modalIsOpen) {
      showCategory();
    }
  }, [modalIsOpen]);

  useEffect(() => {
    getTagList();
  }, [selectedItemId, selectedItemCategory]);

  useEffect(() => {
  }, [selectedTags]);

  const getTagList = async () => {
    const yolos = props.item.yolos;
    let selectedYoloTags: Number[] = [];

    if (yolos && selectedItemId) {
      // Find the yolo and check if it exists before accessing its properties
      const yolo = yolos.find(yolo => yolo.id === selectedItemId);

      if (yolo && yolo.yoloTags) {
        selectedYoloTags = yolo.yoloTags.map(tag => tag.tagId);
      }
    }

    setSelectedTags(selectedYoloTags);

    const getTags = tagArray
      .filter((el) => el.category === selectedItemCategory)
      .map(tag => tag);

    setTags(getTags);
  };

  // const getTagList = async () => {
  //   const yolos = props.item.yolos;
  //   var selectedYoloTags: Number[] = [];
  //
  //   if (yolos && selectedItemId) {
  //     const yolo = yolos.find(yolo => yolo.id === selectedItemId);
  //     if(yolo.yoloTags) {
  //       selectedYoloTags = yolo.yoloTags.map(tag => tag.tagId);
  //     }
  //   }
  //
  //   setSelectedTags(selectedYoloTags)
  //   const tags = tagArray
  //     .filter((el) => el.category === selectedItemCategory)
  //     .map(tag => tag);
  //
  //   setTags(tags);
  // };

  const handleDelete = async (id) => {
    setYoloId(id);
    setShowDelete(true);
  };

  const handleEdit = async (id) => {
    setLeftCounter(300 - props.item.yolos[0].description.length);
    setDescription(props.item.yolos[0].description);
    setUserName(props.item.yolos[0].userName);
    setYoloId(id);
    setShowEdit(true);
  }

  const handleEditYoloName = async (id) => {
    setYoloName(props.item.name)
    setYoloId(id);
    setShowEditYoloName(true);
  }

  const onReadMoreClicked= async () =>{
    setReadMore(!readMore);
  }

  const fetchImage = async (yoloId) => {
      showLoader();
      fetch(process.env.REACT_APP_SERVER_URL + '/getImage?yoloId=' + yoloId)
        .then(res => res.json())
        .then(body => {
          setOldImage(body.image);
          hideLoader()
        })
        .catch(error => {
          throw new Error('Network response was not ok');
        });
  }

  const handlePhoto = async (id) => {
    setYoloId(id);
    acceptedFiles.length = 0;
    await fetchImage(id)
    setShowUpload(true);
  }

  const onUpvoteClick = async (id) => {
    props.handleUpvote(id);
  };

  const onRemoveUpvoteClick = async (id) => {;
    props.handleRemoveUpvote(id);
  };

  const onNotificationClick = async (id)=>{
    props.handleNotificationClick(id)
  }

  const handleAddMoreTags = async (itemId, categoryId) => {
    setSelectedItemId(itemId);
    setSelectedItemCategory(categoryId);
    setShowTags(true);
    props.handleMainFilter(false);
  }

  const handleChangeCategory = async (id, categoryId) => {
    setSelectedItemId(id);
    setSelectedItemCategory(categoryId);
    setModalIsOpen(true);
  }

  const updateSelectedCategory = async (categoryId) => {
    setSelectedItemCategory(categoryId);
  }

  const showCategory = async () => {
    const stored = localStorage.getItem('YoloMeal');
    if (stored) {
      showLoader()
      const userData = JSON.parse(stored);
      fetch(
        process.env.REACT_APP_SERVER_URL +
        '/user-categories?user=' +
        userData.id +
        '&v=' +
        Date.now(),
        {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + userData.token,
          }),
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            hideLoader()
            setCategories(result);
          },
          (error) => {
            hideLoader()
            console.log(error)
          }
        );
    }
  };

  const hideDeleteModal = () => {
    setShowDelete(false);
  }

  const hideEditModal = () => {
    setShowEdit(false);
  }

  const hideEditYoloNameModal = () => {
    setShowEditYoloName(false);
  }

  const hideUploadPhotoModal = () =>{
    setShowUpload(false);
  }

  const onDescriptionChange = async (event) => {
    var str = event.target.value;
    setLeftCounter(300 - str.length);
    setDescription(str);
  }

  const onYoloNameChange = async (event) => {
    var str = event.target.value;
    setYoloName(str);
  }

  const onUserNameChange = async (event) => {
    setUserName(event.target.value);
  }

  const log = async (message: any) => {
    if (process.env.REACT_APP_LOG === 'true') {
      console.log(message);
    }
  }

  const onEdit = async () => {
    showLoader();
    const data = {
      id: yoloId,
      description: description,
      userName: userName,
      userId: props.user.id
    };
    log(data);
    fetch(process.env.REACT_APP_SERVER_URL + "/yolos", {
      method: 'PUT',
      headers: new Headers({
        'Content-Type': 'application/json',
        "Accept": "application/json",
        'Authorization': 'Bearer ' + props.user.token
      }),
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(
        (result) => {
          log(result);
          if (!didCancel) {
            hideLoader();
            if (result.hasOwnProperty('error')) {
              setError(result.error);
            } else {
              setShowEdit(false);
              setError("");
              hideLoader();
              props.loadData();
              setYoloSetting(false);
            }
          }
        },
        (error) => {
          log(error);
          if (!didCancel) {
            setError(error.message);
            hideLoader();
          }
        }
      )
  }

  const onEditYoloName = async () => {
    try {
      const response = await fetch(`https://api.yolomeal.com/yolo/name?yoloId=${yoloId}&new_name=${yoloName}`, {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + props.user.token
        })
      });
      const result = response.headers.get('content-type')?.includes('application/json')
        ? await response.json()
        : await response.text();

      if (!didCancel) {
        hideLoader();
        if (response.ok) {
          console.log("--------------")
          setShowEditYoloName(false);
          // setShowEdit(false);
          setError("");
          props.loadData();
          setYoloSetting(false);
        } else {
          setError(result.error || result);
          console.log("error-------")
        }
      }
    } catch (error) {
      if (!didCancel) {
        setError(error.message);
        hideLoader();
        console.log(error,"error-----")
      }
    }
  }

  const onUpload = async () => {
    showLoader();

    const formData = new FormData();
    formData.append("file", acceptedFiles[0]);
    axios.post(
      process.env.REACT_APP_SERVER_URL + "/yolo-image/" + yoloId + "/image/upload",
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + props.user.token
        }
      }
    ).then((result) => {
      log(result);
      if (!didCancel) {
        setShowUpload(false);
        setError("");
        setYoloSetting(false);
        props.loadData();
        hideLoader();
      }
    }).catch(err => {
      log(err);
      if (!didCancel) {
        hideLoader();
        setError(err);
      }
    });
  }

  const updateCategory = async () =>  {
    try {
      const stored = localStorage.getItem('YoloMeal');
      if (stored) {
        showLoader();
        const userData = JSON.parse(stored);
        fetch(
          process.env.REACT_APP_SERVER_URL +
          '/yolos/category?yoloId=' +
          selectedItemId +
          '&categoryId=' +
          selectedItemCategory,
          {
            method: 'PUT',
            headers: new Headers({
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + userData.token,
            }),
          }
        )
          .then((res) => res.json())
          .then(
            (result) => {
              closeModal()
              props.loadData();
              setYoloSetting(false);
              hideLoader();
            },
            (error) => {
              hideLoader();
              console.log(error)
            }
          );

      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const addTags = async () => {
    try {
      const selectedTagsArray = selectedTags.map(tagId => ({ tagId }));

      const stored = localStorage.getItem('YoloMeal');
      if (stored) {
        showLoader();
        const userData = JSON.parse(stored);
        fetch(
          process.env.REACT_APP_SERVER_URL +
          '/add-more-tags/' +
          selectedItemId,
          {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + userData.token,
            }),
            body: JSON.stringify(selectedTagsArray)
          }
        )
          .then((res) => res.json())
          .then(
            (result) => {
              hideTags()
              props.loadData();
              setYoloSetting(false);
              hideLoader();
            },
            (error) => {
              hideLoader();
              console.log(error)
            }
          );

      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleSave = () => {
    setShowModal(true);
  };

  const isChecked = (categoryId) => {
    return categoryId === selectedItemCategory;
  };

  const closeConfirmBox = () => {
    setShowModal(false);
  }

  const closeTagsConfirmationBox = () => {
    setShowtagsModal(false);
  }

  const handleConfirmYes = () => {
    updateCategory();
    setShowModal(false);
  };

  const handleTagsConfirmYes = () => {
    props.handleMainFilter(false);
    addTags();
    setShowtagsModal(false);
    props.handleMainFilter(true);
  }

  const hideTags = () => {
    setShowTags(false);
  }

  const handleTagsConfirmNo = () => {
    setShowtagsModal(false);
  }

  const handleConfirmNo = () => {
    setShowModal(false);
  };

  const closeShowTags = () => {
    console.log("closed received");
    setShowTags(false);
  }

  const handleTagSave = () => {
    setShowtagsModal(true);
  }

  const onTagClick = (id) => {
    if (selectedTags.some(tag => tag === id)) {
      props.handleMainFilter(false);
      // If the tag is already in the array, remove it
      setSelectedTags(prevTags => prevTags.filter(tag => tag !== id));
    } else {
      props.handleMainFilter(false);
      // If the tag is not in the array, add it
      setSelectedTags(prevTags => [...prevTags, id]);
    }
  };

  const onDelete = async () => {
    // showLoader();
    console.log(props.item)
    fetch(process.env.REACT_APP_SERVER_URL + "/yolos/delete/" + yoloId, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        "Accept": "application/json",
        'Authorization': 'Bearer ' + props.user.token
      }),
    })
      .then(res => res.json())
      .then(
        (result) => {
          log(result);
          if (!didCancel) {
            hideDeleteModal();
            hideLoader();
            setError("");
            setYoloSetting(false);
            props.loadData();
          }
        },
        (error) => {
          log(error);
          if (!didCancel) {
            setError(error.message);
            hideLoader();
          }
        }
      )
  }

  const url = '/restaurant/' + props.item.restaurantId;

  return (
    <section className="upvote-section">
      <div className="upvote-row">
        <div className="upvote-col" >
          <div className="upvote-item" style={{marginTop:"20px"}}>
            <div className="main-upvote-item">
              <div className="upvote-item-top-content">
                <div className="upvote-item-title">
                  <h3 className="text-center" style={{fontSize:'16'}}>
                    {props.item.name === 'Wagu Sirloin' ? 'Wagu Ribeye' : props.item.name}
                  </h3>
                </div>
                {props.showRestaurant && (
                  <div className="upvote-item-location text-center">
                    <Link to={url}>
                      <img src={LocationPointer} alt="" />
                      <p>{props.item.restaurantName}  {generateDollarSigns(props.item.priceLevel)}</p>
                    </Link>
                  </div>
                )}
                <label className="upvote-count">{props.item.upvoteCount} Upvotes</label>
                {!props.isLogged && !YoloSetting &&(
                  <a className="tap-to-upvote-btn upvote_btn" onClick={() => props.handleLogin()}>
                    Tap to upvote
                  </a>
                )}
                {props.isLogged && !props.item.upvoted && props.item.canUpvote && !YoloSetting && (
                  <a className="tap-to-upvote-btn upvote_btn" onClick={() => onUpvoteClick(props.item.id)}>
                    Tap to upvote
                  </a>
                )}
                {props.isLogged && props.item.upvoted && !YoloSetting && (
                  <a className="tap-to-upvote-btn" onClick={() => onRemoveUpvoteClick(props.item.id)}>UPVOTED!</a>
                )}

              </div>
              <div className='text-center'>
                {props.isLogged && props.restaurentPage && !props.item.expired && !YoloSetting && (
                  <a className="tap-to-upvote-btn" style={{cursor:"pointer"}} onClick={() => onNotificationClick(props.item.id)}>Item no longer on the menu?</a>
                )}
                {props.isLogged && props.restaurentPage && props.item.expired && !YoloSetting && (
                  <a className="tap-to-upvote-btn disabled" style={{cursor:"pointer"}}>Thanks for reporting</a>
                )}
              </div>
            </div>

            {props.item.yolos.map((subitem) => {
              return (
                <div className="inner-upvote-item" key={subitem.id}>
                  <div className={`upvote-item-img ${!YoloSetting ? '': 'd-none'}`}>
                    {subitem.image ? (
                      <img
                        src={process.env.REACT_APP_SERVER_URL + '/yolo-image/' + subitem.image}
                        alt=""
                        onClick={() => handlePhoto(subitem.id)}
                        width={100}
                      />
                    ) : (
                      <img src={MissingPhoto} alt="missing-photo" onClick={() => handlePhoto(subitem.id)} />
                    //   <img src={MissingPhoto}
                    // alt="missing-photo"
                    //        onClick={() => handlePhoto(subitem.id)}
                    //        width={100}
                    //   />
                    )}
                  </div>
                  <div className="text-center">
                    {!YoloSetting && (
                      <div className=" upvote-item-info upvote-item-btns">
                        <Link className="tap-to-upvote-btn upvote_btn" to={'/user/' + subitem.userId}>
                          {subitem.userName}
                        </Link>
                      </div>
                    )}
                    <div className="upvote-item-contnet-wrap upvote-item-info-description ">
                      {!YoloSetting && (
                        <div className="text-left upvote-item-contnet d-inline-block">
                          <p>
                            {readMore ? (
                                <TextTruncate
                                  line={2}
                                  element="span"
                                  className="item-text-description text-14"
                                  truncateText="…"
                                  text={subitem.description}
                                  textTruncateChild={
                                    <a className="cursor-pointer font-bold text-14" onClick={() => onReadMoreClicked()}>Read more</a>
                                  }
                                />
                              ) :
                              <span className="upvote-content-txt text-14">
                                {subitem.description}
                                <a className="cursor-pointer font-bold text-14" onClick={() => onReadMoreClicked()}> Read Less</a>
                              </span>
                            }
                          </p>
                        </div>
                      )}
                    </div>
                    {props.editable && (
                      <div className="upvote-item-btns upvote-item-info ">
                        {!YoloSetting &&  (
                          <a className="tap-to-upvote-btn upvote_btn" onClick={() => setYoloSetting(true)}>
                            Yolo Settings
                          </a>
                        )}
                        {YoloSetting && (
                          <section>
                            <a className="tap-to-upvote-btn upvote_btn" onClick={() => handleDelete(subitem.id)}>
                              Delete Yolo
                            </a>
                            <a className="tap-to-upvote-btn upvote_btn" onClick={() => handleEdit(subitem.id)}>
                              Edit Yolo Description
                            </a>
                            <a className="tap-to-upvote-btn upvote_btn" onClick={() => handleEditYoloName(subitem.id)}>
                              Edit Yolo Name
                            </a>
                            <a className="tap-to-upvote-btn upvote_btn" onClick={() => handlePhoto(subitem.id)}>
                              Upload Photo
                            </a>
                            <a className="tap-to-upvote-btn upvote_btn"
                               onClick={() => handleChangeCategory(subitem.id, props.item.categoryId)}>
                              Change Category
                            </a>
                            <a className="tap-to-upvote-btn upvote_btn"
                               onClick={() => handleAddMoreTags(subitem.id, props.item.categoryId)}>
                              Add More Tags
                            </a>
                            <a className="tap-to-upvote-btn upvote_btn bg-danger white"
                               onClick={() => setYoloSetting(false)}>
                              Close Settings
                            </a>
                          </section>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <DeleteModal
        isOpen={showDelete}
        user={props.user}
        handleModalClose={hideDeleteModal}
        yoloId={yoloId}
        onDelete={onDelete}
      />

      <EditYoloDescriptionModal
        isOpen={showEdit}
        handleModalClose={hideEditModal}
        yoloId={yoloId}
        user={props.user}
        leftCounter={leftCounter}
        description={description}
        userName={userName}
        onUserNameChange={onUserNameChange}
        onDescriptionChange={onDescriptionChange}
        onEdit={onEdit}
      />

      <EditYoloNameModal
        isOpen={showEditYoloName}
        user={props.user}
        yoloName={yoloName}
        onEditYoloName={onEditYoloName}
        onYoloNameChange={onYoloNameChange}
        handleModalClose={hideEditYoloNameModal}
      />

      <UploadPhotoModal
        isOpen={showUpload}
        oldImage={oldImage}
        handleModalClose={hideUploadPhotoModal}
        user={props.user}
        yoloId={yoloId}
        files={files}
        onUpload={onUpload}
        error={error}
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        acceptedFiles={acceptedFiles}
      />

      <ChangeCategoryModal
        isOpen={modalIsOpen}
        handleModalClose={closeModal}
        user={props.user} yoloId={yoloId}
        handleSave={handleSave}
        updateSelectedCategory={updateSelectedCategory}
        isChecked={isChecked}
      />

      <AddMoreTagsModal
        isOpen={showTags}
        handleModalClose={closeShowTags}
        user={props.user}
        yoloId={yoloId}
        handleTagSave={handleTagSave}
        items={props.item}
        selectedItemCategory={selectedItemCategory}
        selectedItemId={selectedItemId}
        selectedTags={selectedTags}
        tags={tags}
        onTagClick={onTagClick}
      />

      <ConfirmationModal
        isOpen={showModal}
        handleModalClose={closeConfirmBox}
        handleConfirmYes={handleConfirmYes}
        handleConfirmNo={handleConfirmNo}
      />

      <TagsConfirmationModal
        isOpen={showtagsModal}
        handleModalClose={closeTagsConfirmationBox}
        handleTagsConfirmYes={handleTagsConfirmYes}
        handleTagsConfirmNo={handleTagsConfirmNo}
      />
    </section>
  );
};

export default ItemNew;
